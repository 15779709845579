import * as React from "react"
import { graphql, HeadFC, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ScheduleDemo from "../components/schedule_demo"

const AboutPage = () => {
    const data = useStaticQuery(graphql`
        query {
            aboutImage: file(relativePath: { eq: "about-images.png" }) {
                base
                childImageSharp {
                  gatsbyImageData(quality: 100, width: 700)
                }
            }
        }
    `)
    
    return (
        <Layout>
            <SEO title="About us" />
            <section className="bg-white">
                <div className="items-center max-w-screen-xl px-4 py-24 mx-auto xl:pt-32">
                    <h2 className="text-4xl tracking-tight font-extrabold text-gray-900">About us</h2>
                    <div className="lg:grid lg:grid-cols-8 lg:gap-16 xl:gap-24 py-8 mx-auto">
                        <div className="col-span-4">
                            <div className="mb-8">
                            <p className="font-light text-gray-500 sm:text-xl">At <b>Boxloading</b>, we love certainty. Sending boxes all over the world has always contained a lot of guesswork. How many boxes do I need? What kind of boxes should I use? How should I pack them? By building software that produces optimized box selections, we move away from guessing and towards knowing.</p>

                            <p className="font-light text-gray-500 sm:text-xl mt-4">We also love win-win situations. Not knowing can have a large impact on both the cost and the environment. We believe that these two aspects must go hand in hand. Reducing the CO<sub>2</sub> footprint and saving our customers money is a deal we are truely proud about!</p>

                            <p className="font-light text-gray-500 sm:text-xl mt-4">The team behind BoxLoading is based in Malmö, Sweden.</p>
                            </div>
                            <div className="mt-8">
                                <ScheduleDemo className="bg-gray-100" />
                            </div>
                        </div>
                        <div className="col-span-4 h-auto mt-4 sm:mt-0 relative">
                        <GatsbyImage 
                            className="lg:absolute lg:top-0 lg:left-0 h-full"
                            alt={data.aboutImage.base.split(".")[0]}
                            image={data.aboutImage.childImageSharp.gatsbyImageData} />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default AboutPage

export const Head: HeadFC = () => <title>BoxLoading</title>